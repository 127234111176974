<!-- 权限 -->
<template>
  <div class="resourceManage fx-col fx-1">
      <el-card>
        <div slot="header">
          <el-form
            class="form-item-no-mb"
            ref="searchForm"
            inline
            :model="searchForm"
            size="small">
            <el-form-item label="姓名">
              <el-input v-model="searchForm.userName" clearable placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="部门">
              <el-input v-model="searchForm.createDeptName" clearable placeholder="请输入部门"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="_query()">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-button type="primary" icon="el-icon-plus" @click="_addCoursePopup">新增</el-button>
        </div>
      </el-card>
      <div class="fx-row mt16 bac-fff fx-1">
        <!-- 列表 -->
        <div class="w200 fx-1">
          <el-table
            :header-cell-style="cellHeaderStyle"
            :cell-style="cellStyle"
            v-loading="loading"
            class="index-list-table"
            :data="tableData"
            height="calc(100vh - 380px)"
            style="width: 100%">
              <el-table-column prop="userName" label="姓名" width="180"></el-table-column>
              <el-table-column prop="createDeptName" label="部门" width="180"></el-table-column>
              <el-table-column prop="deptName" label="负责部门">
                <template slot-scope="scope">
                  <el-popover trigger="hover" placement="top">
                    <span style="font-size: 12px;color: #303133; max-width: 400px;display: inline-block;"> {{ scope.row.deptName }}</span>
                    <div slot="reference" class="hover-title">
                      {{ scope.row.deptName }}
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="144" fixed="right">
                <template slot-scope="{row,$index}">
                  <el-button type="text" class="text-btn mr24" @click="_edit(row,$index)">编辑</el-button>
                  <el-button type="text" class="text-btn mr24" @click="_delete(row,$index)">删除</el-button>
                </template>
              </el-table-column>
          </el-table>
          <!-- 分页--start -->
          <el-pagination
            class="fx-row-end pt16"
            @size-change="_handleSizeChange"
            @current-change="_handleCurrentChange"
            :current-page="searchForm.page"
            :page-sizes="[10, 20, 30, 40, 50]"
            :page-size="searchForm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="searchForm.total">
          </el-pagination>
          <!-- 分页--end -->
        </div>
      </div>
      <!-- 编辑&新增弹窗 -->
      <responseUserEdit
        ref="responseUserEdit"
        :propTitle="propTitle"
      />
  </div>
</template>
<script>
import {
  getResponseUserListApi,
  getResponseUserDeleteAPi
} from '@/api/settings'
// export const getResponseUserListApi = (data) => post(API.getResponseUserList, data)// 负责人列表
// export const getResponseUserDetailApi = (data) => get(API.getResponseUserDetail, data)// 负责人--详情
// export const getResponseUserAddOrEditApi = (data) => post(API.getResponseUserAddOrEdit, data)// 负责人--添加和修改
// export const getResponseUserDeleteAPi = (data) => post(API.getResponseUserDelete, data)// 负责人--批量删除
import responseUserEdit from './components/responseUserEdit'
import tableFirstCol from '@/mixins/tableFirstCol'
export default {
  mixins: [tableFirstCol],
  components: {
    responseUserEdit
  },
  data () {
    return {
      tableData: [],
      searchForm: {
        userName: '', // 姓名
        deptName: '', // 部门
        page: 1,
        pageSizes: 10,
        total: 1
      },
      propTitle: '',
      loading: false
    }
  },
  created () {
    this._query()
  },
  methods: {
    _query () {
      // 查询
      this.loading = true
      getResponseUserListApi({
        ...this.searchForm
      }).then(res => {
        if (res.code === 200) {
          this.tableData = res.object.results
          this.searchForm.total = res.object.total
          this.loading = false
        } else {
          this.loading = false
        }
      })
    },
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      this._query()
    },
    _handleCurrentChange (val) {
      this.searchForm.page = val
      this._query()
    },
    _addCoursePopup () {
      // 新增
      this.$refs.responseUserEdit.show()
      this.propTitle = '新增'
    },
    _edit (row) {
      // 编辑
      this.$refs.responseUserEdit.show(row)
      this.propTitle = '编辑'
    },
    _delete (row, index) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        getResponseUserDeleteAPi([row.id]).then(res => {
          if (res.code === 200) {
            this.$message.success('删除成功')
            this._query()
          }
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.form-item-no-mb {
  /deep/.el-form-item {
    margin-bottom: 0;
  }
}
/deep/.index-list-table {
  thead {
    background-color: #ffffff !important;
  }
  tr {
    height: 51px;
    height: 54px;
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
  }
  th {
    background: #f6f7f9 !important;
  }
}
.add-group-btn {
  width: 116px;
  border: 1px solid #0089ff;
  font-size: 14px;
  color: #0089ff;
}
.delete-group-btn {
  width: 60px;
  border: 1px solid #f04134;
  font-size: 14px;
  color: #f04134;
}
/deep/.el-form-item {
  margin-bottom: 16px !important;
  margin-right: 32px !important;
}
/deep/.el-form-item__label {
  color: #000000;
}
/deep/ .el-button {
  font-size: 14px;
  height: 32px;
}
/deep/.el-button--text {
  margin: 0 10px 0px 0!important;
}
.hover-title{
  min-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/deep/.el-popover{
  width:200px!important;
  max-width: 200px!important;
}
/depp/.tooltip-text {
  max-width: 100px!important;
  display: inline-block;
}
</style>
<style lang="scss">
.tooltip-width {
  max-width: 400px;
}
